import React from "react";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";

import {
  AboutUs,
  Button,
  Hero,
  ImgGrid,
  Layout,
  SectionTitle,
  SEO,
} from "components";

const Home = () => {
  const { t } = useTranslation();

  return (
    <Layout>
      <SEO
        title={t`config.header.name`}
        description={t`informations.paragraph_1`}
      />
      <article className="home-page">
        <section className="home-page__hero container">
          <Hero />
        </section>
        <div className="gray">
          <section id="info" className="home-page__about container">
            <SectionTitle title={t`config.title.whoWeAre`} />
            <AboutUs />
            <Button
              className="home-page__register"
              text="config.button.signin"
              to={t("fee.link_url")}
              newTab
            />
          </section>
        </div>
        <section className="home-page__partners">
          <SectionTitle title={t`config.title.partners`} center />
          <ImgGrid />
        </section>
      </article>
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
